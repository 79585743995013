import React from "react";
import "./style.scss";
import underline from "../../../images/csm/new-underline.png";
import call from '../../../images/csm/callPng.png';
import email from '../../../images/csm/emailPng.png';

const IvrSection = () => {
  return (
    <div className="ivr-csm">
      <div className="help"> Have more questions? </div>
      <div className="new-underline">
        <img src={underline} loading="lazy" />
      </div>
      <div className="available">Available from 9am-9pm from Monday-Friday</div>
      <div className="ivr-body">
        <div className="call">
          <div className="title"><img src={call} className="ivricon" />Call</div>
          <div className="desc mtop-1">+91 7827135490 </div>
        </div>
        <div className="email">
          <div className="title"><img src={email} className="ivricon" /> Email</div>
          <div className="desc"><a href="mailto:contact@kraftshala.com"><u>contact@kraftshala.com</u></a></div>
        </div>
      </div>
    </div>
  );
};

export default IvrSection;
