import VarunSatia from "../../../images/csm/trainers/varunCsm.png";
import Ishu from "../../../images/csm/trainers/ishuCsm.png";
import Ashi from "../../../images/csm/trainers/AshiCsm.png";
import Ankita from "../../../images/csm/trainers/AnkitaCsm.png";
import Nrithya from "../../../images/csm/trainers/NrithyaCsm.png";
import Shraddha from "../../../images/csm/trainers/ShradhaCsm.webp";
import Kavya from "../../../images/csm/trainers/KavyaCsm.webp";
import Praveen from "../../../images/csm/trainers/PraveenCsm.webp";
//company images


export const trainersImages = [
  {
    image: VarunSatia,
    alt: "Kraftshala Digital Marketing Trainer_Varun_Satia",
  },
  {
    image: Ishu,
    alt: "Kraftshala Digital Marketing Trainer_Ishu_Sharma",
  },
  {
    image: Praveen,
    alt: "Kraftshala Digital Marketing Trainer_Praveen_Kasmas",
  },
  // {
  //   image: Shivang,
  //   alt: "Kraftshala Digital Marketing Trainer_Shivang_Sethiya",
  // },
  {
    image: Ashi,
    alt: "Kraftshala Digital Marketing Trainer_Ashi_Jain",
  },
  {
    image: Ankita,
    alt: "Kraftshala Digital Marketing Trainer_Ankita_Gaba",
  },
  {
    image: Nrithya,
    alt: "Kraftshala Digital Marketing Trainer_Nrithya_Randhir",
  },
  {
    image: Shraddha,
    alt: "Kraftshala Digital Marketing Trainer_Shraddha_More",
  },
  {
    image: Kavya,
    alt: "Kraftshala Digital Marketing Trainer_Kavita",
  },
  // {
  //   image: Warren,
  //   alt: "Kraftshala Digital Marketing Trainer_Warren_Luis",
  // },
 
];
