import React from "react";
import "./styles.scss";
import { Link } from "@reach/router";
import girlIcon from "../../../images/csm/girlPng.png";
import suitcaseIcon from "../../../images/csm/suitcaseIcon.png";

const index = () => {
  return (
    <div className="startYourCareer">
      <div className="csm-container">
        <img className="girlIcon" src={girlIcon} loading="lazy" />
        <div className="header">Start your career with</div>
        <div className="csm">Content and Social Media Launchpad</div>
        <Link
          className="startYourCareer-csm"
          to={`/content-writing-course/`}
        >
          Apply Now
        </Link>
        <img className="suitcaseIcon" src={suitcaseIcon} loading="lazy" />

      </div>
    </div>
  );
};

export default index;
