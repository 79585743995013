import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "./styles.scss";
import orangeTickIcon from '../../../images/csm/orangeTick.png';
import  CompaniesImg  from "../../../images/csm/CompaniesImg.png";

const index = () => {
  const [itemSelected, setItemSelected] = useState(0);

  return (
    <div className="csm-placement-report">
      <h2 className="placement-report-sub-title">PLACEMENTS</h2>
      <div className="placement-report-header">
       Get full-time roles, internship or <br/> freelancing gigs from the best brands!
        {/* <br /> */}
      </div>
      {/* <div className="new-underline">
        <StaticImage src="../../../images/csm/new-underline.png" />
      </div> */}
      {/* <h3 className="placement-report-desc">
      The intensity of the program + ambition of our students =  <br/>
        <b> 98% placement rate </b> for you!
      </h3> */}
      <div className="header-container">
        <div className="header">
          {" "}
          <span><img className="orangeIcon" src={orangeTickIcon} /></span> Highest CTC &nbsp; <span className="yellow-mob"> 9.2L </span>
        </div>
        <div className="header">
          {" "}
          <span><img className="orangeIcon" src={orangeTickIcon} /></span>
          Average CTC &nbsp;<span className="yellow-mob"> 4.9L </span>
        </div>
        <div className="header">
          <span><img className="orangeIcon" src={orangeTickIcon} /></span>
          <span className="yellow-mob">98% &nbsp;</span> Placement Rate
        </div>
      </div>

      <div className="flex chain-img">
        <StaticImage
          className="first"
          src="../../../images/csm/downChainImg.png"
        />

        <StaticImage src="../../../images/csm/downChainImg.png" />
      </div>
      <div className="header-container h-286">
        {/* <div className="body">
          <div className="title"> Time Period </div>
          <div
            className={`desc ${itemSelected === 0 ? "active" : ""}`}
            onClick={() => setItemSelected(0)}
          >
            Jan - Jun 2022
          </div>
          <div
            className={`desc ${itemSelected === 1 ? "active" : ""}`}
            onClick={() => setItemSelected(1)}
          >
            July - Dec 2022
          </div>
          <div
            className={`desc ${itemSelected === 2 ? "active" : ""}`}
            onClick={() => setItemSelected(2)}
          >
            Jan - Jun 2023
          </div>
          <div
            className={`desc ${itemSelected === 3 ? "active" : ""}`}
            onClick={() => setItemSelected(3)}
          >
            July - Dec 2023
          </div>
        </div> */}
        <div className="main-body">
          {/* <div className="title">
            <div className="title-header">
              <div className="head">Placement <span className="mobile-none">Rate</span> {data[itemSelected].totalStudents == 34 && '(Ongoing)'}:</div>
              <div className={`placement-rate ${data[itemSelected].placementRate === '96%' && 'position-correction'}`}>
                {data[itemSelected].placementRate}
              </div>
            </div>
            <div className="title-header pl-4">
              <div className="head">Total Students {data[itemSelected].totalStudents == 34 && '(Estimated)'}:</div>
              <div className="placement-rate">
                {data[itemSelected].totalStudents}
              </div>
            </div>
          </div> */}
          <div className="sub-header"> Recruiters:</div>
          <div className="company-img">
            {/* {data[itemSelected].images.map((image) => ( */}
            <img src={CompaniesImg} alt="company" />
            {/* ))} */}
          </div>
        </div>
      </div>
      <div className="flex chain-img">
        <StaticImage
          className="first"
          src="../../../images/csm/downChainImg.png"
        />

        <StaticImage src="../../../images/csm/downChainImg.png" />
      </div>
      <div className="header-container center">
        <div className="bg-header">Alumni backgrounds:</div>
        <div className="graph-container">
          <div className="graphs">
            <div className="graph-value">27%</div>
            <div className="graph bcom"></div>
            <div className="course">B.com</div>
          </div>
          <div className="graphs">
            <div className="graph-value">24%</div>
            <div className="graph ba"></div>
            <div className="course">BA/BBA</div>
          </div>
          <div className="graphs">
            <div className="graph-value">15%</div>
            <div className="graph btech"></div>
            <div className="course">B.Tech</div>
          </div>
          <div className="graphs">
            <div className="graph-value">13%</div>
            <div className="graph mba"></div>
            <div className="course">MBA</div>
          </div>
          <div className="graphs">
            <div className="graph-value">12%</div>
            <div className="graph other"></div>
            <div className="course">Other</div>
          </div>
          <div className="graphs">
            <div className="graph-value">9%</div>
            <div className="graph bsc"></div>
            <div className="course">B.Sc</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
