import React, { useEffect, useState } from "react";
import "./styles.scss";
import underline from "../../../images/csm/new-underline.png";
import InstaStory from "./InstaStory";
import yellowTicket from "../../../images/csm/yellowTicket.png";
import DotsPc from "../../../images/csm/CourseDottedPc.png";
import AiDottedRight from "../../../images/csm/AiDottedRight.png";
import brainIcon from "../../../images/csm/Icons/brainIcon.png";
import connectIcon from "../../../images/csm/Icons/connectIcon.png";
import settingsIndustryIcon from "../../../images/csm/Icons/settingsIndustryIcon.png";
import imgIcon from "../../../images/csm/Icons/imgIcon.png";
import contentIcon from "../../../images/csm/Icons/contentIcon.png";
import personIcon from "../../../images/csm/Icons/personIcon.png";
import analysisIcon from "../../../images/csm/Icons/analysisIcon.png";
import researchIcon from "../../../images/csm/Icons/researchIcon.png";
import competetorAnalysysIcon from "../../../images/csm/Icons/competetorAnalysysIcon1.png";

const index = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // State to track the current video index

  useEffect(() => {
    if(currentVideoIndex === 0){
      setCurrentVideoIndex(1);
    }
  },[currentVideoIndex]);

  return (
    <div className="Ai-learning">
      <img src={DotsPc} className="dottedBgPc" alt="" />
      <img src={AiDottedRight} className="AiDottedRightPc" alt="" />
      <div className="subTitle">AI FOR CONTENT WRITERS</div>
      <div className="ai-title">
        You will also 2X your career growth by learning AI
      </div>
      <img src={underline} className="ai-underline" loading="lazy" />
      <div className="ai-desc">
        We’ll help you learn how to use AI as your sidekick in your content
        writing journey to <br />
        <b>double your productivity and creativity.</b>
      </div>
      <div className="flex-container">
        <div className="story-board">
          <img src={yellowTicket} className="yellow-ticket" />
          <InstaStory
            setCurrentVideoIndex={setCurrentVideoIndex}
            currentVideoIndex={currentVideoIndex}
          />
        </div>
        {(currentVideoIndex === 1 || currentVideoIndex === 2) && (
          <div className="story-desc">
            <div className="story-header">Idea Machine for Social</div>
            <div className="ul">
              <div className="story-item"><img src={brainIcon} className="story-icon" loading="lazy" />Divergent thinking</div>
              <div className="story-item"><img src={connectIcon} className="story-icon" loading="lazy" />Broad base of references</div>
              <div className="story-item"><img src={settingsIndustryIcon} className="story-icon" loading="lazy" />Across industries</div>
            </div>
          </div>
        )}
        {currentVideoIndex === 3 && (
          <div className="story-desc">
            <div className="story-header">Be a Design Ninja</div>
            <div className="ul">
              <div className="story-item"><img src={imgIcon} className="story-icon  imgIcon" loading="lazy" />Text to image tools</div>
              <div className="story-item"><img src={contentIcon} className="story-icon" loading="lazy" />Multilingual content</div>
              <div className="story-item"><img src={personIcon} className="story-icon personIcon" loading="lazy" />Personalized creatives</div>
            </div>
          </div>
        )}
        {currentVideoIndex === 4 && (
          <div className="story-desc">
            <div className="story-header">Keyword Research for Blogs</div>
            <div className="ul">
              <div className="story-item"><img src={analysisIcon} className="story-icon" loading="lazy" />Analyse data</div>
              <div className="story-item"><img src={researchIcon} className="story-icon researchIcon" loading="lazy" />Keyword Research</div>
              <div className="story-item"><img src={competetorAnalysysIcon} className="story-icon" loading="lazy" />Competitor analysis</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default index;
