import React from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import midjourney from "../../../images/csm/tools/midJourney.png";
import stableDifussion from "../../../images/csm/tools/Stable-Diffusion-Logo.png";
import LinkedIn from '../../../images/csm/tools/Linkedin-logo-png.png';
import Youtube from '../../../images/csm/tools/pngwing.png';
import medium from '../../../images/csm/tools/medium.png';
import typeshare from '../../../images/csm/tools/typeshare.png';
import hemingWay from '../../../images/csm/tools/hemingway.png';
import quilbot from '../../../images/csm/tools/quilbot.png';
import grammerrly from '../../../images/csm/tools/grammerrly.png';
import ToolsMobile from "./ToolsMobile";
import Gsuit from "../../../images/csm/tools/Gsuit.png";
import PencilIcon from "../../../images/csm/pensilIYellowcon.png";
import SettingIcon from "../../../images/csm/settingIcon.png";

const Tools = () => {
  return (
    <div className="tools-covered csm-tools" id="tools">
      <div className="tools-header"><img src={PencilIcon} className="pencil" loading="lazy" />Tools you’ll learn  <img src={SettingIcon} className="setting" loading="lazy" /> <br/> <StaticImage className="under-line-New" src="../../../images/csm/new-underline.png" /></div>
      <div className="tools-container">
        <div className="tools-row tools-pc">
          <div className="tools-title">Creation Tools +AI</div>
          <div className="tools">
            <div className="imageContainer big-img">
              <StaticImage src="../../../images/csm/tools/ChatGPT_logo.png" />
            </div>
            <img src={midjourney} />
            <img src={stableDifussion} className="stableFution" />
            <div className="imageContainer big-img">
              <StaticImage src="../../../images/csm/tools/Canva.png" />
            </div>
            <div className="imageContainer">
              <StaticImage src="../../../images/csm/tools/google-bard3872.logowik.png" />
            </div>
            <div className="imageContainer">
              <StaticImage
                style={{ maxWidth: "54px" }}
                className="dallE"
                src="../../../images/csm/tools/dall-e.png"
              />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Content Platforms</div>
          <div className="tools">
            <div className="imageContainer">
              <StaticImage src="../../../images/csm/tools/Instagram_logo_2016.png" />
            </div>
            <div className="imageContainer">
              <img src={LinkedIn} />
            </div>
            <img src={Youtube} />
            <div className="imageContainer">
            </div>
            <div className="imageContainer">
              <StaticImage src="../../../images/csm/tools/X-Logo 1.png" />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Publishing Platforms</div>
          <div className="tools">
            <img src={medium} />
            <img src={typeshare} />         
            <div className="imageContainer">
              <StaticImage src="../../../images/csm/tools/notion.png" />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Writing Support</div>
          <div className="tools">
            <img src={hemingWay} />
            <img src={quilbot} />
            <img src={grammerrly} />
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Operations</div>
          <div className="tools">
            <img src={Gsuit} className="gsuit" loading="lazy" />
          </div>
        </div>
        <div className="tools-mob">
          <ToolsMobile />
        </div>
      </div>
    </div>
  );
};

export default Tools;
