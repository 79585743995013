import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import underline from "../../../images/csm/new-underline.png";
import "./styles.scss";
import { data, headerData } from "./data";
import CourseBody from "./CourseBody";
import bookPng from '../../../images/csm/bookYellowIcon.png';

function AdmissionProcess(props) {
  const [itemSelected, setItemSelected] = useState(0);
  const [itemSelectedMob, setItemSelectedMob] = useState(null);


  const handleDataFn = (operation) => {
    if (operation == "forward") {
      if (itemSelected < 9) setItemSelected(itemSelected + 1);
    } else {
      if (itemSelected > 0) setItemSelected(itemSelected - 1);
    }
  };
  const handleDataFnMob = (index) => {
    if (itemSelectedMob === index) {
      setItemSelectedMob(null);
    } else {
      setItemSelectedMob(index);
    }
  };

  return (
    <div className="csm-course" id="curriculum">
      <h2 className="sub-header">PROGRAM SYLLABUS</h2>
      <div className="header">
        <img className="bookPng" src={bookPng} loading="lazy" />
        Step-by-step learning through cases and projects to up your<br/> social and content game
      </div>
      <div className="new-underline" style={{width:'306px'}}>
        <img src={underline} loading="lazy" />
      </div>
      <div className="desc">
        The most comprehensive learning experience that ensures you master not
        just functional skills,
        <br />
        but have a stellar portfolio, human skills, excel comfort & much more!
      </div>
      <div className="box-container">
        <div className="menu">
          {headerData.map((data, index) => {
            return (
              <h3
                className={`item ${itemSelected === index && "active"}`}
                onClick={() => setItemSelected(index)}
              >
                <span className="count">
                  {index > 8 ? index + 1 : "0" + (index + 1)}
                </span>{" "}
                {data}
              </h3>
            );
          })}
        </div>
        <CourseBody course={data[itemSelected]} handleDataFn={handleDataFn} />
      </div>
      <div className="box-container-mob">
        <div className="menu">
          {headerData.map((courseName, index) => {
            return (
              <>
                <div
                  className={`item ${itemSelectedMob === index && "active"}`}
                  onClick={() => handleDataFnMob(index)}
                >
                  <h3 className="course">
                    <div className="count">
                      {index > 8 ? index + 1 : "0" + (index + 1)}
                    </div>{" "}
                    {courseName}
                  </h3>
                  {itemSelectedMob === index ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                 
                </div>

                <div
                  className={`${
                    itemSelectedMob === index ? "active-body" : "inactive"
                  }`}
                >
                  {itemSelectedMob !== null && <CourseBody
                    course={data[itemSelectedMob]}
                    handleDataFn={handleDataFnMob}
                  /> }
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AdmissionProcess;
