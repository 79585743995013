import clip1 from "../../../images/csm/clip1.png";
import clip2 from "../../../images/csm/clip2.png";
import Img1 from "../../../images/csm/courseforImg1.png";
import Img2 from "../../../images/csm/courseforImg2.png";
import Img3 from "../../../images/csm/courseforImg3.png";
import Img4 from "../../../images/csm/courseforImg4.png";


export const Data = [
  {
    image: Img1,
    clip: clip1,

    title: "The Aspiring Creators",
    desc: `<li>If you want to be in a creative career but unsure of how to start</li>
    <br/>
    <li>If you dont know whether you can make a career out of this</li>`,
  },
  {
    image: Img2,
    clip: clip2,
    title: "The Forever Learners",
    desc: `<li>If you are already in marketing &  want to learn content creation</li>
             <br/>
    <li>If you want to build structure and nurture that creative side</li>`,
  },
  {
    image: Img3,
    clip: clip1,
    title: "The SOLO-Prenuers",
    desc: `<li>If you are building something - be it your personal brand / business</li>
   <br/>
    <li>If you want to get perspectives on your business from experts</li>`,
  },
  {
    image: Img4,
    clip: clip2,
    title: "The Career Transitioners",
    desc: `<li> If you want to make a shift into a creative career</li>
    <br/>
    <li>If you want to get into amazing  content and social media roles</li>`,
  },
];
