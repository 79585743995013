import React, { useState } from "react";
import { tableData } from "./tableData";
import WeighMachineIcon from '../../../images/csm/weighMachinePng.png';
import CsmLogo from "../../../images/csm/CSM_LOGO.png";

import "./styles.scss";
import underline from "../../../images/csm/new-underline.png";

function Placements() {
  const [item, setItem] = useState("roi");

  return (
    <React.Fragment>
      <div className="csm-main-placements" id="Benefits">
        <div className="placements-container">
          <div className="recruiting-partner">
            <div className="csm-student-slider">
             
              <div className="slider-section-head">
                <h5>KRAFTSHALA vs OTHERS  <span className="WeighMachineIcon"><img src={WeighMachineIcon} loading="lazy" /></span> </h5>
              </div>
              <h2>There are many content writing courses out there. So why us?</h2>
              <img src={underline} />
              {/* <div className="batch-info-csm mtop-1p5">
                Your success = Our placement rate
                <br />
                Here’s a table that’ll help you decide <b>whether</b> Kraftshala
                is right for you.
              </div> */}
            </div>
          </div>
          {/* table */}
          <div className="tables-container">
            <div className="cover">
              <div className="menu">
                <div
                  className={`menu-item ${item === "roi" && "active"}`}
                  onClick={() => setItem("roi")}
                >
                ROI
                </div>
                <div
                  className={`menu-item ${item === "jobs" && "active"}`}
                  onClick={() => setItem("jobs")}
                >
                Outcomes
                </div>
                <div
                  className={`menu-item ${item === "curriculum" && "active"}`}
                  onClick={() => setItem("curriculum")}
                >
                Curriculum
                </div>
                {/* <div
                  className={`menu-item ${item === "network" && "active"}`}
                  onClick={() => setItem("network")}
                >
                Network
                </div> */}
              </div>
            </div>
            <div className="table-section">
              <div className="sticker">
              </div>
              <table>
                <thead>
                  <th className="firstHr"></th>
                  <th><img src={CsmLogo} loading="lazy"/></th>
                  <th>Offline courses</th>
                  <th>Online Degree Courses</th>
                  <th>Campus/<br/>Pro Courses</th>
                </thead>
                <tbody>
                  {item === 'roi' && tableData?.Roi?.map((tr) => {
                    return (
                      <tr>
                        {tr.map((td,index) => (
                          <td  key={index}
                          
                            className="td-csm"
                            dangerouslySetInnerHTML={{ __html: td }}></td>
                        ))}
                      </tr>
                    );
                  })}
                  {item === 'jobs' && tableData?.Jobs?.map((tr) => {
                    return (
                      <tr>
                        {tr.map((tdContent, index) => (
                          <td key={index} dangerouslySetInnerHTML={{ __html: tdContent }} />
                        ))}
                      </tr>
                    );
                  })}
                  {item === 'curriculum' && tableData?.curriculum?.map((tr) => {
                    return (
                      <tr>
                        {tr.map((tdContent, index) => (
                          <td key={index} dangerouslySetInnerHTML={{ __html: tdContent }} />
                        ))}
                      </tr>
                    );
                  })}
                  {/* {item === 'network' && tableData?.network?.map((tr) => {
                    return (
                      <tr>
                        {tr.map((tdContent, index) => (
                          <td key={index} dangerouslySetInnerHTML={{ __html: tdContent }} />
                        ))}
                      </tr>
                    );
                  })} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Placements;
