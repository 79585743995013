import React from "react";
import Slider from "react-slick";
import CsmPageCard from "../../TestimonialCard/CsmPageCard/index";
import { Data } from "./data";
import "./styles.scss";
import underLine from "../../../images/csm/new-underline.png";
import heartIcon from "../../../images/csm/heartIcon.png";

function AlumniCarousal() {
  const settings = {
    prevArrow: "",
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerMode: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false, 
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="home-cards csm-main" id="testimonials">
        <div className="placements-container">
          <h2 className="alumini-title">
            <span className="heartIcon">
              <img src={heartIcon} loading="lazy" />
            </span>
            Our Awesome Alumni
          </h2>
          <div className="new-y-underline">
            <img src={underLine} loading="lazy" />
          </div>
          <div className="alumni-description">
            Btw, also the largest & the most connected network of marketing
            folks in the country!
          </div>
          <div className="placement-slider alumini-card-csm-page alumni-slider">
            <Slider {...settings}>
              {Data.map((info, key) => (
                <CsmPageCard
                  key={key}
                  index={key}
                  image={info.image}
                  testimonial={info.testimonial}
                  ytLink={info.ytLink ? info.ytLink : ""}
                />
              ))}
            </Slider>
          </div>

          {/* <div className="max-container">
            <Link to={`/content-writing-course/`} className="alumni-btn">
              Add an image (you?)
            </Link>
            <img src={greyheartIcon} className="greyHeart" loading="lazy" />
          </div> */}
        </div>

      </div>
    </React.Fragment>
  );
}

export default AlumniCarousal;
