import React from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import { trainersImages } from "./data";
import graduationHatIcon from "../../../images/csm/graduationHatIcon.png";

import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";

function Trainers() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="csm-main-trainers">
      <div className="will-be-training mlp-bg">
        <h2 className="training-section-subtitle">MEET YOUR FACULTY</h2>
        <div className="training-section-title">
          <img
            className="graduationHatIcon"
            src={graduationHatIcon}
            loading="lazy"
          />{" "}
          2-3 hours of live classes + feedback every day <br />{" "}
        </div>
        <div className="new-underline">
          <StaticImage
            style={{ marginLeft: "3em",width:"220px" }}
            src="../../../images/csm/new-underline.png"
          />
        </div>
        <div className="training-section-desc">
          In-house and external experts who’ve build most-loved brands through
          content and storytelling
        </div>
        <div className="trainers-slider">
          <Slider {...settings}>
            {trainersImages.map((info, key) => (
              <div
                className={`trainer-card`}
                key={key}
              >
                <div className="card">
                  <div className="card-img">
                    <LazyLoad once>
                      <img
                        className={
                          "customized-trainer-img"
                        }
                        src={info.image}
                        alt={info.alt}
                        title={info.alt}
                      />
                    </LazyLoad>
                  </div>
             
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Trainers;
