import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import Slider from "react-slick";
import { data } from "./data";
import ToSumItUpCard from "../../TestimonialCard/CsmPageCard/ToSumItUpCard";

const index = () => {
  const settings = {
    prevArrow: "",
    dots: false,
    //infinite: true,
    speed: 1000,
    //autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: true,
    slidesToShow: 3,
    //slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          // centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="sum-it">
      <div className="sum-it-header">
        To sum it all up {":)"}
        <br />
      </div>
      <div className="new-underline">
        <StaticImage src="../../../images/csm/new-underline.png" />
      </div>
      <h2 className="sum-it-desc">
        This content writing course will help you
        <b> make the big jump </b>in your 
        career!
      </h2>
      <div className="sum-it-up-slider">
        <Slider {...settings}>
          {data.map((info, key) => (
            <ToSumItUpCard key={key} from={info.From} to={info.To} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default index;
