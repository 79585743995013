import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import peopleIcon from "../../../images/csm/poopleIcon.png";
import profileIcon from "../../../images/csm/profileIcon.png";
import steps from "../../../images/csm/stepsAdmission.png";
import steps2 from "../../../images/csm/step2.png";

const index = () => {
  return (
    <div className=" csm-Admission-process">
      <img className="profileIcon" src={profileIcon} loading="lazy" />
      <h2 className="Admission-process-header">
        Admission Process{" "}
        <img className="peopleIcon" src={peopleIcon} loading="lazy" />
        
        <br />
      </h2>
      <div className="new-underline">
        <StaticImage src="../../../images/csm/new-underline.png" />
      </div>
      <p className="admission-sub-heading">
        Our placement numbers matter a lot to us. And thus,
        <b>it's not a free pass.</b>  
      </p>

      <div className="second-div-admission">
        <div className="firstt-div">
          <img className="steps1" src={steps} loading="lazy" />
          <img className="steps2" src={steps2} loading="lazy"  />
        </div>
        <div className="secondd-div">
          <p><b>Complete Application</b> </p>
          <p> Give the <b>Marketing Screening Test</b> where you decode an ad using  the concepts that we teach you</p>
          <p>If you clear the process,<b>enroll and <br/> start your journey!</b> </p>
        </div>
      </div>
    </div>
  );
};

export default index;
