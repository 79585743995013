import React from "react";
import arrowDown from '../../../images/csm/CurvyDownArrowImg.png';

const ToSumItUpCard = ({from,to}) => {
  return (
    <div className="cards">
      <div className="card1">
        <div className="data">
          <span className="from">From: </span>
          {from}
        </div>
      </div>
      <img className="arrowDown" src={arrowDown } loading="lazy" />
      <div className="card2">
        <div className="data">
          <span className="to">To: </span>
          {to}
        </div>
      </div>
    </div>
  );
};

export default ToSumItUpCard;
