import Kaizar from "../../../images/csm/Testimonials/Kaizar.png";
import KaizarTestimonial from "../../../images/csm/Testimonials/KaizarTestimonial.png";
import Sourav from "../../../images/csm/Testimonials/Sourav.png";
import SouravTestimonial from "../../../images/csm/Testimonials/SouravTestimonial.png";
import Gazal from "../../../images/csm/Testimonials/Gazal.png";
import GazalTestimonial from "../../../images/csm/Testimonials/GazalTestimonial.png";
import Nikhil from "../../../images/csm/Testimonials/Nikhil.png";
import NikhilTestimonial from "../../../images/csm/Testimonials/NikhilTestimonial.png";
import Prerna from "../../../images/csm/Testimonials/Prerna.png";
import PrernaTestimonial from "../../../images/csm/Testimonials/PrernaTestimonial.png";
import Nitish from "../../../images/csm/Testimonials/Nitish.png";
import NitishTestimonial from "../../../images/csm/Testimonials/NitishTestimonial.png";
import Atul from "../../../images/csm/Testimonials/Atul.png";
import AtulTestimonial from "../../../images/csm/Testimonials/AtulTestimonial.png";
import Kajal from "../../../images/csm/Testimonials/Kajal.png";
import KajalTestimonial from "../../../images/csm/Testimonials/KajalTestimonial.png";
import Srishti from "../../../images/csm/Testimonials/Srishti.png";
import SrishtiTestimonial from "../../../images/csm/Testimonials/SrishtiTestimonial.png";

export const Data = [
  {
    image: Kaizar,
    testimonial: KaizarTestimonial,
  },
  {
    image: Sourav,
    testimonial: SouravTestimonial,
  },
  {
    image: Gazal,
    testimonial: GazalTestimonial,
  },
  {
    image: Nikhil,
    testimonial: NikhilTestimonial,
  },
  {
    image: Prerna,
    testimonial: PrernaTestimonial,
  },
  {
    image: Nitish,
    testimonial: NitishTestimonial,
  },
  {
    image: Atul,
    testimonial: AtulTestimonial,
  },
  {
    image: Srishti,
    testimonial: SrishtiTestimonial,
    ytLink: "https://youtu.be/y-NJvUgsQ38",
  },
  {
    image: Kajal,
    testimonial: KajalTestimonial,
  },
  // {
  //   image: Swapnil,
  //   testimonial: SwapnilTestimonial,
  // },
  // {
  //   image: Mahikya,
  //   testimonial: MahikyaTestimonial,
  //   ytLink: "https://youtu.be/ZEYxIULJsp0",
  // },
];
