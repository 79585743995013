import AiTools from "../../../images/csm/tools/AiTools.png";
import ContentTools from "../../../images/csm/tools/ContentTools.png";
import PublishingTools from "../../../images/csm/tools/PublishTools.png";
import WritingTools from "../../../images/csm/tools/writingTools.png";
import OperationTools from "../../../images/csm/tools/operationTools.png";

export const data = [
  {
    img: AiTools,
  },
  {
    img: ContentTools,
  },
  {
    img: PublishingTools,
  },
  {
    img: WritingTools,
  },
  {
    img: OperationTools,
  },
];
